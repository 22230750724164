import React, { useEffect } from "react";
import { Alert, AlertTitle, Button, Container, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { getIdentityDocumentsForMeetingData, IdentityDocumentCard, identityDocuments, MeetingData, toggleMeetingData, useMeetingData } from "./ApplicationMeetingData";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { filterIdentityDocumentCardProps, useLinkToMeeting, zMeetingDataWithResolversGroup2 } from "./ApplicationMeeting";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";
import { TestingInfoBlock } from "../../components/TestingInfo";
import { numberInWords, pluralise } from "../../utils/strings";
import { useSetAppBoxContent } from "../../components/AppBarBox";
import TestApplicationAlert from "../../components/TestApplicationAlert";

export const Element: React.FC = () => {
  const { linkTo, application_id } = useLinkToMeeting()

  const { meetingData, setMeetingData, navigateWithMeetingData } = useMeetingData()

  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({ params: { id: application_id ?? '' } })

  const form = useForm<MeetingData>({
    resolver: zodResolver(
      zMeetingDataWithResolversGroup2
        .superRefine((value, context) => {
          const minimumDocumentCount = 3

          const countSelectedGroup1Documents = Object.values(value.group1 ?? {})
            .filter((document) => document.selected)
            .length
          const requiredGroup2DocumentCount = minimumDocumentCount - countSelectedGroup1Documents

          const mergedGroup2Documents = {
            ...value.group2a,
            ...value.group2b_last3mo,
            ...value.group2b_last12mo,
            ...value.group2b_anyTime,
          }
          const countSelectedGroup2Documents = Object.values(mergedGroup2Documents)
            .filter((document) => document.selected)
            .length

          const countMoreGroup2DocumentsRequired = Math.max(0, requiredGroup2DocumentCount - countSelectedGroup2Documents)

          // At least 3 documents in total must be selected
          if (countMoreGroup2DocumentsRequired > 0) {
            context.addIssue({
              code: 'custom',
              message: `At least ${numberInWords(countMoreGroup2DocumentsRequired)} more ${pluralise('document', countMoreGroup2DocumentsRequired)} must be selected`,
              path: ['root'],
            })
          }
        })
    ),
    mode: 'onBlur',
    defaultValues: meetingData,
  })
  const hasErrors = Object.keys(form.formState.errors).length > 0

  useEffect(() => {
    form.trigger()
  }, [form, meetingData])

  useSetAppBoxContent(
    form.formState.errors.root
      ? {
        Element: (
          <Alert severity="warning">
            {form.formState.errors.root.message}
          </Alert>
        ),
        state: `group-2-errors:${form.formState.errors.root.message}`,
      }
      : {
        Element: (
          <Alert severity="success">
            You have selected enough documents to continue.
          </Alert >
        ),
        state: 'group-2-no-errors',
      }
  )

  return <>
    <Helmet>
      <title>Application Meeting</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" marginTop={8} marginBottom={3}>
        Group2a Documents
      </Typography>
      {Object.entries(identityDocuments.group2a ?? {}).map(([key, value], index) => {
        const groupKey = key as keyof typeof identityDocuments.group2a
        return (
          <IdentityDocumentCard
            key={key}
            document={value}
            {...(filterIdentityDocumentCardProps({
              selected: (meetingData.group2a ?? {})[groupKey]?.selected,
              onSelect: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2a', groupKey as never, 'selected', setMeetingData);
                form.setValue('group2a', newMeetingData.group2a)
              },
              eBulkApplication: getEBulkApplicationByID.data,
              verified: (meetingData.group2a ?? {})[groupKey]?.verified,
              onVerify: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2a', groupKey as never, 'verified', setMeetingData)
                form.setValue('group2a', newMeetingData.group2a)
              },
            }))}
            sx={{ marginBottom: 2 }}
          />
        )
      })}

      <Typography component="h1" variant="h5" marginTop={5} marginBottom={3}>
        Group2b Documents
      </Typography>
      <Typography component="h1" variant="h6" marginBottom={3}>
        Issued within the last 3 months
      </Typography>
      {Object.entries(identityDocuments.group2b_last3mo ?? {}).map(([key, value], index) => {
        const groupKey = key as keyof typeof identityDocuments.group2b_last3mo
        return (
          <IdentityDocumentCard
            key={key}
            document={value}
            {...(filterIdentityDocumentCardProps({
              selected: (meetingData.group2b_last3mo ?? {})[groupKey]?.selected,
              onSelect: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2b_last3mo', groupKey as never, 'selected', setMeetingData);
                form.setValue('group2b_last3mo', newMeetingData.group2b_last3mo)
              },
              eBulkApplication: getEBulkApplicationByID.data,
              verified: (meetingData.group2b_last3mo ?? {})[groupKey]?.verified,
              onVerify: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2b_last3mo', groupKey as never, 'verified', setMeetingData)
                form.setValue('group2b_last3mo', newMeetingData.group2b_last3mo)
              },
            }))}
            sx={{ marginBottom: 2 }}
          />
        )
      })}

      <Typography component="h1" variant="h6" marginTop={5} marginBottom={3}>
        Issued within the last 12 months
      </Typography>
      {Object.entries(identityDocuments.group2b_last12mo ?? {}).map(([key, value], index) => {
        const groupKey = key as keyof typeof identityDocuments.group2b_last12mo
        return (
          <IdentityDocumentCard
            key={key}
            document={value}
            {...(filterIdentityDocumentCardProps({
              selected: (meetingData.group2b_last12mo ?? {})[groupKey]?.selected,
              onSelect: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2b_last12mo', groupKey as never, 'selected', setMeetingData);
                form.setValue('group2b_last12mo', newMeetingData.group2b_last12mo)
              },
              eBulkApplication: getEBulkApplicationByID.data,
              verified: (meetingData.group2b_last12mo ?? {})[groupKey]?.verified,
              onVerify: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2b_last12mo', groupKey as never, 'verified', setMeetingData)
                form.setValue('group2b_last12mo', newMeetingData.group2b_last12mo)
              },
            }))}

            sx={{ marginBottom: 2 }}
          />
        )
      })}

      <Typography component="h1" variant="h6" marginTop={5} marginBottom={3}>
        Issued at any time (must still be valid)
      </Typography>
      {Object.entries(identityDocuments.group2b_anyTime ?? {}).map(([key, value], index) => {
        const groupKey = key as keyof typeof identityDocuments.group2b_anyTime
        return (
          <IdentityDocumentCard
            key={key}
            document={value}
            {...(filterIdentityDocumentCardProps({
              selected: (meetingData.group2b_anyTime ?? {})[groupKey]?.selected,
              onSelect: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2b_anyTime', groupKey as never, 'selected', setMeetingData);
                form.setValue('group2b_anyTime', newMeetingData.group2b_anyTime)
              },
              eBulkApplication: getEBulkApplicationByID.data,
              verified: (meetingData.group2b_anyTime ?? {})[groupKey]?.verified,
              onVerify: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group2b_anyTime', groupKey as never, 'verified', setMeetingData)
                form.setValue('group2b_anyTime', newMeetingData.group2b_anyTime)
              },
            }))}
            sx={{ marginBottom: 2 }}
          />
        )
      })}

      {form.formState.errors.root && (
        <Alert severity="error">
          <AlertTitle>Errors</AlertTitle>
          {form.formState.errors.root.message}
        </Alert>
      )}

      <TestingInfoBlock>
        <Alert severity="info" sx={{ mt: 3 }}>
          <AlertTitle>Only shown in Test Mode</AlertTitle>
          You have selected the following documents so far:
          <ol>
            {getIdentityDocumentsForMeetingData(meetingData).map((each) => {
              const qualifier = each.groupKey.endsWith('3mo')
                ? <> <em>(within the last 3 months)</em></>
                : each.groupKey.endsWith('12mo')
                  ? <> <em>(within the last year)</em></>
                  : each.groupKey.endsWith('anyTime')
                    ? <> <em>(must be valid)</em></>
                    : ''
              return <li key={`${each.groupKey}.${each.docKey}`}>{each.doc.title}{qualifier}</li>
            })}
          </ol>
        </Alert>
      </TestingInfoBlock>

      <TestApplicationAlert dbsApp={getEBulkApplicationByID.data} sx={{ marginTop: 3 }} />

      <Button variant="contained" disabled={hasErrors} onClick={() => { navigateWithMeetingData(linkTo('summary')) }} sx={{ mt: 3, mb: 6 }}>
        See Selected Document Summary
      </Button>
    </Container>
  </>
}

export default Element
