import React from "react";
import { Alert, AlertTitle, Box, Button, Container, Typography } from "@mui/material";
import { Form, useNavigate, useParams } from "react-router-dom";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { queryClient } from "../../bootstrapping/InitReactQuery";
import { paymentMethodTypeMapping } from "../../utils/option-data";

export const Element: React.FC = () => {
  const navigate = useNavigate()

  const { customer_id, application_id } = useParams<{ customer_id?: string, application_id?: string }>()
  if (!customer_id) throw new Error('customer_id is required')
  if (!application_id) throw new Error('application_id is required')

  // Determine the cost of the application
  const usePriceForEBulkApplicationByID = apiClientHooks.usePriceForEBulkApplicationByID({ params: { id: application_id } })

  const appPricing = usePriceForEBulkApplicationByID.data

  const [selectedPaymentMethod, setSelectedPaymentMethod] = React.useState<string | undefined>(undefined)

  const choosePaymentMethod = (method: string) => {
    // If the method is Stripe, navigate to the Stripe payment page
    if (method === 'stripe') {
      navigate(`/${customer_id}/applications/${application_id}/payment/stripe`)
    } else 
    // Otherwise, set the selected payment method
    {
      setSelectedPaymentMethod(method)
    }
  }

  const payForEBulkApplicationByID = apiClientHooks.usePayForEBulkApplicationByID({
    params: {
      id: application_id,
    },
  })

  const onNoPaymentNecessary = async () => {
    if (!appPricing?.price) throw new Error('Price is required')
    await payForEBulkApplicationByID.mutateAsync({
      payment_method: 'none',
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })
    navigate(`/${customer_id}`)
  };

  const onPayByStripe = async () => {
    navigate(`/${customer_id}/applications/${application_id}/payment/stripe`)
  }

  const onPayByInvoice = async () => {
    if (!appPricing?.price) throw new Error('Price is required')
    await payForEBulkApplicationByID.mutateAsync({
      payment_method: 'invoice',
      payment_pence: appPricing.price.priceInPence,
    })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getGlobalStats') })
    navigate(`/${customer_id}`)
  }

  return <>
    <Helmet>
      <title>Payment</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" marginBottom={3}>
            Payment
          </Typography>
          <Typography component="h1" variant="h6" marginBottom={3} align="center">
            {appPricing?.price.label} - £{((appPricing?.amountToPayInPence ?? 0) / 100).toFixed(2)} to pay
            {(appPricing?.alreadyPaidInPence ?? 0) > 0 && <>
              <br />
              <em>(already paid: £{((appPricing?.alreadyPaidInPence ?? 0) / 100).toFixed(2)})</em>
            </>}
          </Typography>
          <Typography variant="body1" marginBottom={2}>
            Please choose a payment method to continue:
          </Typography>
          {appPricing?.allowedPaymentMethods.map(method => (
            <Button
              key={method}
              variant='contained'
              color={selectedPaymentMethod === method ? 'success' : 'primary'}
              sx={{ marginBottom: 2 }}
              onClick={() => choosePaymentMethod(method)}
            >
              {paymentMethodTypeMapping[method].label}
            </Button>
          ))}
          {selectedPaymentMethod === 'none' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>No Payment Necessary</AlertTitle>
              There is no payment necessary for this application.
              <br />
              <br />
              <Button variant='contained' onClick={onNoPaymentNecessary}>
                Continue without Payment
              </Button>
            </Alert>
          )}
          {selectedPaymentMethod === 'stripe' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>Stripe Payment</AlertTitle>
              To pay by card using Stripe, please click the button below.
              <br />
              <br />
              <Button variant='contained' onClick={onPayByStripe}>
                Pay by Stripe
              </Button>
            </Alert>
          )}
          {selectedPaymentMethod === 'invoice' && (
            <Alert severity="success" sx={{ marginTop: 2 }}>
              <AlertTitle>Invoice Payment</AlertTitle>
              Please confirm that you wish for this application to be paid for by invoice.
              <br />
              <br />
              <Button variant='contained' onClick={onPayByInvoice}>
                Pay by Invoice
              </Button>
            </Alert>
          )}
        </Box>
      </Form>
    </Container>
  </>
}

export default Element
