import { z } from 'zod'
import { schemas } from '../generated/api/client'

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

export const labelsForEBulkApplicationStatus: Record<EBulkApplication['status'], string> = {
  'draft': 'Draft',
  'awaiting_customer_approval': 'Awaiting Customer Approval',
  'awaiting_payment': 'Awaiting Payment',
  'needs_changes': 'Needs Changes',
  'awaiting_countersigning': 'Awaiting Countersigning',
  'awaiting_submission_to_DBS': 'Awaiting Submission to DBS',
  'awaiting_response_from_DBS': 'Awaiting Response from DBS',
  'rejected': 'Rejected',
  'restarted': 'Restarted',
  'completed': 'Completed',
}

export const labelsForEBulkApplicationPaymentMethod: Record<NonNullable<EBulkApplication['payment_method']>, string> = {
  'none': 'No Payment Necessary',
  'paypal': 'PayPal',
  'stripe': 'Stripe',
  'invoice': 'Invoice',
}

/**
 * Converts the labelsForEBulkApplication object into an array of objects that can be used as options for a select filter in a table
 * 
 * From:
 * ```ts
 * { 'example': 'Example', 'another': 'Another' }
 * ```
 * 
 * To:
 * ```ts
 * [ { label: 'Example', value: 'example' }, { label: 'Another', value: 'another' }  ]
 * ```
 */
export const getTableFilterSelectOptions = (labels: Record<string, string>): { label: string, value: string }[] => {
  return Object.entries(labels).map(([value, label]) => ({ label, value }))
}

// [
//   { label: 'Draft', value: 'draft' },
//   { label: 'Awaiting Customer Approval', value: 'awaiting_customer_approval' },
//   { label: 'Awaiting Payment', value: 'awaiting_payment' },
//   { label: 'Needs Changes', value: 'needs_changes' },
//   { label: 'Awaiting Countersigning', value: 'awaiting_countersigning' },
//   { label: 'Awaiting Submission to DBS', value: 'awaiting_submission_to_DBS' },
//   { label: 'Awaiting Response from DBS', value: 'awaiting_response_from_DBS' },
//   { label: 'Rejected', value: 'rejected' },
//   { label: 'Completed', value: 'completed' },
// ]
