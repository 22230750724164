import { schemas } from "../generated/api/client"
import { z } from "zod"
import { apiClientHooks } from "../bootstrapping/InitApiClient"
import { queryClient } from "../bootstrapping/InitReactQuery"
import IconButtonWithTooltip, { IconButtonWithTooltipProps } from "./IconButtonWithTooltip"

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

const CompleteChangesIconButton: React.FC<{ dbsApp: EBulkApplication } & Omit<IconButtonWithTooltipProps, 'onclick'>> = ({ dbsApp, ...iconButtonWithProps }) => {
  const completeChangesForEBulkApplicationByID = apiClientHooks.useCompleteChangesForEBulkApplicationByID({ params: { id: dbsApp.id } })

  const onClickButton = async () => {
    await completeChangesForEBulkApplicationByID.mutateAsync(undefined)
    await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplications') })
    await queryClient.invalidateQueries({ queryKey: apiClientHooks.getKeyByAlias('getEBulkApplicationByID') })
  }
  
  const applicableStatuses: Array<typeof dbsApp.status> = [
    'needs_changes',
  ]
  return applicableStatuses.includes(dbsApp.status)
    ? <IconButtonWithTooltip {...iconButtonWithProps} onClick={onClickButton} />
    : null
}

export default CompleteChangesIconButton
