import React from "react";
import { Alert, AlertTitle, Box, Container, Typography } from "@mui/material";
import { Form, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";

export const Element: React.FC = () => {
  const { customer_id, application_id } = useParams<{ customer_id?: string, application_id?: string }>()
  if (!customer_id) throw new Error('customer_id is required')
  if (!application_id) throw new Error('application_id is required')

  // Get the Stripe Checkout ID in `session_id` from the querystring
  const session_id = new URLSearchParams(window.location.search).get('session_id')
  if (!session_id) throw new Error('session_id is required')

  // Load the Checkout Session Status from the server
  const stripeStatus = apiClientHooks.useStripeStatusForEBulkApplicationByID({
    params: {
      id: application_id,
    },
    queries: {
      session_id,
    },
  })
  const isPaid = stripeStatus.data?.payment_status === 'paid'

  return <>
    <Helmet>
      <title>Pay by Stripe</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Form method="post">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography component="h1" variant="h5" marginBottom={3}>
            Pay by Stripe
          </Typography>
          {isPaid && <>
            <Alert severity="success" sx={{ marginBottom: 3 }}>
              <AlertTitle>Payment Successful</AlertTitle>
              Your payment has been successfully processed. The application is now ready for countersigning.
              {/* TODO: Add a button to return to the dashboard */}
            </Alert>
          </>}
        </Box>
      </Form>
    </Container>
  </>
}

export default Element
