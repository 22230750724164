import { z } from "zod"
import { schemas } from "../generated/api/client"

export const asOptions = (mapping: Record<string, { label: string }>): { id: string, label: string }[] =>
  Object.entries(mapping).map(([id, { label }]) => ({ id, label }))

// TODO: Confirm that these are the correct labels
type TitleType = z.infer<typeof schemas.TitleType>
export const titleMapping: Record<TitleType, { label: string }> = {
  'MISS': { label: 'Miss' },
  'MR': { label: 'Mr' },
  'MRS': { label: 'Mrs' },
  'MS': { label: 'Ms' },
  // TODO: Can we add a separator here?
  'BARON': { label: 'Baron' },
  'BARONESS': { label: 'Baroness' },
  'BRIGADIER': { label: 'Brigadier' },
  'CANON': { label: 'Canon' },
  'CAPTAIN': { label: 'Captain' },
  'DR': { label: 'Doctor' },
  'DUCHESS': { label: 'Duchess' },
  'DUKE': { label: 'Duke' },
  'ESQ': { label: 'Esquire' },
  'FATHER': { label: 'Father' },
  'HON': { label: 'Honourable' },
  'INSPECTOR': { label: 'Inspector' },
  'LADY': { label: 'Lady' },
  'LORD': { label: 'Lord' },
  'LT COL': { label: 'Lieutenant Colonel' },
  'MAJOR': { label: 'Major' },
  'MOST REVER': { label: 'Most Reverend' },
  'PASTOR': { label: 'Pastor' },
  'PROFESSOR': { label: 'Professor' },
  'RABBI': { label: 'Rabbi' },
  'REV DR': { label: 'Reverend Doctor' },
  'REVEREND': { label: 'Reverend' },
  'RT REVEREN': { label: 'Rt Reverend' },
  'SIR': { label: 'Sir' },
  'SISTER': { label: 'Sister' },
  'SQUADRON L': { label: 'Squadron Leader' },
  'WG CDR': { label: 'Wing Commander' },
}

// TODO: Confirm that these are the correct labels
type CountryCodeType = z.infer<typeof schemas.ISOCountryCodeType>
export const countryCodeMapping: Record<CountryCodeType, { label: string }> = {
  'GB': { label: 'United Kingdom (UK), including England and Wales, the Channel Islands and Isle of Man; excluding Scotland and Northern Ireland' },
  'AD': { label: 'Andorra' },
  'AE': { label: 'United Arab Emirates' },
  'AF': { label: 'Afghanistan' },
  'AG': { label: 'Antigua and Barbuda' },
  'AI': { label: 'Anguilla' },
  'AL': { label: 'Albania' },
  'AM': { label: 'Armenia' },
  'AN': { label: 'Netherlands Antilles' },
  'AO': { label: 'Angola' },
  'AQ': { label: 'Antarctica' },
  'AR': { label: 'Argentina' },
  'AS': { label: 'American Samoa' },
  'AT': { label: 'Austria' },
  'AU': { label: 'Australia' },
  'AW': { label: 'Aruba' },
  'AZ': { label: 'Azerbaijan' },
  'BA': { label: 'Bosnia and Herzegovina' },
  'BB': { label: 'Barbados' },
  'BD': { label: 'Bangladesh' },
  'BE': { label: 'Belgium' },
  'BF': { label: 'Burkina Faso' },
  'BG': { label: 'Bulgaria' },
  'BH': { label: 'Bahrain' },
  'BI': { label: 'Burundi' },
  'BJ': { label: 'Benin' },
  'BM': { label: 'Bermuda' },
  'BN': { label: 'Brunei Darussalam' },
  'BO': { label: 'Bolivia' },
  'BR': { label: 'Brazil' },
  'BS': { label: 'Bahamas' },
  'BT': { label: 'Bhutan' },
  'BV': { label: 'Bouvet Island' },
  'BW': { label: 'Botswana' },
  'BY': { label: 'Belarus' },
  'BZ': { label: 'Belize' },
  'CA': { label: 'Canada' },
  'CC': { label: 'Cocos (Keeling) Islands' },
  'CD': { label: 'Congo, The Democratic Republic of the' },
  'CF': { label: 'Central African Republic' },
  'CG': { label: 'Congo' },
  'CH': { label: 'Switzerland' },
  'CI': { label: "Cote D'Ivoire" },
  'CK': { label: 'Cook Islands' },
  'CL': { label: 'Chile' },
  'CM': { label: 'Cameroon' },
  'CN': { label: 'China' },
  'CO': { label: 'Colombia' },
  'CR': { label: 'Costa Rica' },
  'CU': { label: 'Cuba' },
  'CV': { label: 'Cape Verde' },
  'CX': { label: 'Christmas Island' },
  'CY': { label: 'Cyprus' },
  'CZ': { label: 'Czech Republic' },
  'DE': { label: 'Germany' },
  'DJ': { label: 'Djibouti' },
  'DK': { label: 'Denmark' },
  'DM': { label: 'Dominica' },
  'DO': { label: 'Dominican Republic' },
  'DZ': { label: 'Algeria' },
  'EC': { label: 'Ecuador' },
  'EE': { label: 'Estonia' },
  'EG': { label: 'Egypt' },
  'EH': { label: 'Western Sahara' },
  'ER': { label: 'Eritrea' },
  'ES': { label: 'Spain' },
  'ET': { label: 'Ethiopia' },
  'FI': { label: 'Finland' },
  'FJ': { label: 'Fiji' },
  'FK': { label: 'Falkland Islands (Malvinas)' },
  'FM': { label: 'Micronesia, Federated States of' },
  'FO': { label: 'Faroe Islands' },
  'FR': { label: 'France' },
  'FX': { label: 'France, Metropolitan' },
  'GA': { label: 'Gabon' },
  'GD': { label: 'Grenada' },
  'GE': { label: 'Georgia' },
  'GF': { label: 'French Guiana' },
  'GH': { label: 'Ghana' },
  'GI': { label: 'Gibraltar' },
  'GL': { label: 'Greenland' },
  'GM': { label: 'Gambia' },
  'GN': { label: 'Guinea' },
  'GP': { label: 'Guadeloupe' },
  'GQ': { label: 'Equatorial Guinea' },
  'GR': { label: 'Greece' },
  'GS': { label: 'South Georgia and the South Sandwich Islands' },
  'GT': { label: 'Guatemala' },
  'GU': { label: 'Guam' },
  'GW': { label: 'Guinea-Bissau' },
  'GY': { label: 'Guyana' },
  'HK': { label: 'Hong Kong' },
  'HM': { label: 'Heard Island and McDonald Islands' },
  'HN': { label: 'Honduras' },
  'HR': { label: 'Croatia' },
  'HT': { label: 'Haiti' },
  'HU': { label: 'Hungary' },
  'ID': { label: 'Indonesia' },
  'IE': { label: 'Ireland' },
  'IL': { label: 'Israel' },
  'IN': { label: 'India' },
  'IO': { label: 'British Indian Ocean Territory' },
  'IQ': { label: 'Iraq' },
  'IR': { label: 'Iran, Islamic Republic of' },
  'IS': { label: 'Iceland' },
  'IT': { label: 'Italy' },
  'JM': { label: 'Jamaica' },
  'JO': { label: 'Jordan' },
  'JP': { label: 'Japan' },
  'KE': { label: 'Kenya' },
  'KG': { label: 'Kyrgyzstan' },
  'KH': { label: 'Cambodia' },
  'KI': { label: 'Kiribati' },
  'KM': { label: 'Comoros' },
  'KN': { label: 'Saint Kitts and Nevis' },
  'KP': { label: "Korea, Democratic People's Republic of" },
  'KR': { label: 'Korea, Republic of' },
  'KW': { label: 'Kuwait' },
  'KY': { label: 'Cayman Islands' },
  'KZ': { label: 'Kazakhstan' },
  'LA': { label: "Lao People's Democratic Republic" },
  'LB': { label: 'Lebanon' },
  'LC': { label: 'Saint Lucia' },
  'LI': { label: 'Liechtenstein' },
  'LK': { label: 'Sri Lanka' },
  'LR': { label: 'Liberia' },
  'LS': { label: 'Lesotho' },
  'LT': { label: 'Lithuania' },
  'LU': { label: 'Luxembourg (alt)' },
  'LV': { label: 'Latvia' },
  'LY': { label: 'Libyan Arab Jamahiriya' },
  'LX': { label: 'Luxembourg' },
  'MA': { label: 'Morocco' },
  'MC': { label: 'Monaco' },
  'MD': { label: 'Moldova, Republic of' },
  'MG': { label: 'Madagascar' },
  'MH': { label: 'Marshall Islands' },
  'MK': { label: 'Macedonia, The Former Yugoslav Republic of' },
  'ML': { label: 'Mali' },
  'MM': { label: 'Myanmar' },
  'MN': { label: 'Mongolia' },
  'MO': { label: 'Macao' },
  'MP': { label: 'Northern Mariana Islands' },
  'MQ': { label: 'Martinique' },
  'MR': { label: 'Mauritania' },
  'MS': { label: 'Montserrat' },
  'MT': { label: 'Malta' },
  'MU': { label: 'Mauritius' },
  'MV': { label: 'Maldives' },
  'MW': { label: 'Malawi' },
  'MX': { label: 'Mexico' },
  'MY': { label: 'Malaysia' },
  'MZ': { label: 'Mozambique' },
  'NA': { label: 'Namibia' },
  'NC': { label: 'New Caledonia' },
  'NE': { label: 'Niger' },
  'NF': { label: 'Norfolk Island' },
  'NG': { label: 'Nigeria' },
  'NI': { label: 'Nicaragua' },
  'NL': { label: 'Netherlands' },
  'NO': { label: 'Norway' },
  'NP': { label: 'Nepal' },
  'NR': { label: 'Nauru' },
  'NU': { label: 'Niue' },
  'NZ': { label: 'New Zealand' },
  'OM': { label: 'Oman' },
  'PA': { label: 'Panama' },
  'PE': { label: 'Peru' },
  'PF': { label: 'French Polynesia' },
  'PG': { label: 'Papua New Guinea' },
  'PH': { label: 'Philippines' },
  'PK': { label: 'Pakistan' },
  'PL': { label: 'Poland' },
  'PM': { label: 'Saint Pierre and Miquelon' },
  'PN': { label: 'Pitcairn' },
  'PR': { label: 'Puerto Rico' },
  'PS': { label: 'Palestinian Territory, Occupied' },
  'PT': { label: 'Portugal' },
  'PW': { label: 'Palau' },
  'PY': { label: 'Paraguay' },
  'QA': { label: 'Qatar' },
  'RE': { label: 'Reunion' },
  'RO': { label: 'Romania' },
  'RU': { label: 'Russian Federation' },
  'RW': { label: 'Rwanda' },
  'SA': { label: 'Saudi Arabia' },
  'SB': { label: 'Solomon Islands' },
  'SC': { label: 'Seychelles' },
  'SD': { label: 'Sudan' },
  'SE': { label: 'Sweden' },
  'SG': { label: 'Singapore' },
  'SH': { label: 'Saint Helena' },
  'SI': { label: 'Slovenia' },
  'SJ': { label: 'Svalbard and Jan Mayen' },
  'SK': { label: 'Slovakia' },
  'SL': { label: 'Sierra Leone' },
  'SM': { label: 'San Marino' },
  'SN': { label: 'Senegal' },
  'SO': { label: 'Somalia' },
  'SR': { label: 'Suriname' },
  'ST': { label: 'Sao Tome and Principe' },
  'SV': { label: 'El Salvador' },
  'SY': { label: 'Syrian Arab Republic' },
  'SZ': { label: 'Swaziland' },
  'TC': { label: 'Turks and Caicos Islands' },
  'TD': { label: 'Chad' },
  'TF': { label: 'French Southern Territories' },
  'TG': { label: 'Togo' },
  'TH': { label: 'Thailand' },
  'TJ': { label: 'Tajikistan' },
  'TK': { label: 'Tokelau' },
  'TM': { label: 'Turkmenistan' },
  'TN': { label: 'Tunisia' },
  'TO': { label: 'Tonga' },
  'TP': { label: 'East Timor' },
  'TR': { label: 'Turkey' },
  'TT': { label: 'Trinidad and Tobago' },
  'TV': { label: 'Tuvalu' },
  'TW': { label: 'Taiwan' },
  'TZ': { label: 'Tanzania, United Republic of' },
  'UA': { label: 'Ukraine' },
  'UG': { label: 'Uganda' },
  'UM': { label: 'United States Minor Outlying Islands' },
  'US': { label: 'United States of America (USA)' },
  'UY': { label: 'Uruguay' },
  'UZ': { label: 'Uzbekistan' },
  'VA': { label: 'Holy See (Vatican City State)' },
  'VC': { label: 'Saint Vincent and the Grenadines' },
  'VE': { label: 'Venezuela' },
  'VG': { label: 'Virgin Islands, British' },
  'VI': { label: 'Virgin Islands, U.S.' },
  'VN': { label: 'Vietnam' },
  'VU': { label: 'Vanuatu' },
  'WF': { label: 'Wallis and Futuna' },
  'WS': { label: 'Samoa' },
  'YE': { label: 'Yemen' },
  'YT': { label: 'Mayotte' },
  'YU': { label: 'Yugoslavia' },
  'ZA': { label: 'South Africa' },
  'ZM': { label: 'Zambia' },
  'ZR': { label: 'Zaire' },
  'ZW': { label: 'Zimbabwe' },
}

type LanguagePreferenceType = z.infer<typeof schemas.LanguagePreferenceType>
export const languagePreferenceMapping: Record<LanguagePreferenceType, { label: string }> = {
  'english': { label: 'English' },
  'welsh': { label: 'Welsh' },
}

type GenderType = z.infer<typeof schemas.GenderType>
export const genderMapping: Record<GenderType, { label: string }> = {
  'male': { label: 'Male' },
  'female': { label: 'Female' },
}

type DriverLicenceTypeType = z.infer<typeof schemas.DriverLicenceTypeType>
export const driverLicenceTypeMapping: Record<DriverLicenceTypeType, { label: string }> = {
  'paper': { label: 'Paper' },
  'photo': { label: 'Photo' },
}

type PaymentMethod = z.infer<typeof schemas.EBulkApplicationPaymentMethod>
export const paymentMethodTypeMapping: Record<PaymentMethod, { label: string }> = {
  'none': { label: 'No Payment Necessary' },
  'paypal': { label: 'PayPal (now)' },
  'stripe': { label: 'Stripe (now)' },
  'invoice': { label: 'Invoice (later)' },
}
