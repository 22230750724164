import React, { useEffect } from "react";
import { Alert, AlertTitle, Button, Container, Typography } from "@mui/material";
import { apiClientHooks } from "../../bootstrapping/InitApiClient";
import { Helmet } from "react-helmet-async";
import { getIdentityDocumentsForMeetingData, IdentityDocumentCard, identityDocuments, MeetingData, toggleMeetingData, useMeetingData } from "./ApplicationMeetingData";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { filterIdentityDocumentCardProps, useLinkToMeeting, zMeetingDataWithResolversGroup1 } from "./ApplicationMeeting";
import { TestingInfoBlock } from "../../components/TestingInfo";
import { useSetAppBoxContent } from "../../components/AppBarBox";
import TestApplicationAlert from "../../components/TestApplicationAlert";

/////////////////////////////////////////////////////////////////////////////
/// NOTE! This is a common file between applicant and customer applications
/////////////////////////////////////////////////////////////////////////////

export const Element: React.FC = () => {
  const { linkTo, application_id } = useLinkToMeeting()

  const { meetingData, setMeetingData, navigateWithMeetingData } = useMeetingData()

  const getEBulkApplicationByID = apiClientHooks.useGetEBulkApplicationByID({ params: { id: application_id ?? '' } })
  const _hasPassport = !!getEBulkApplicationByID.data?.data.ApplicantIdentityDetails?.PassportDetails?.PassportNumber
  const _hasDriverLicenceWithPhoto = getEBulkApplicationByID.data?.data.ApplicantIdentityDetails?.DriverLicenceDetails?.DriverLicenceType === 'photo'

  const form = useForm<MeetingData>({
    resolver: zodResolver(
      zMeetingDataWithResolversGroup1
        .superRefine((value, context) => {
          const countSelectedGroup1Documents = Object.values(value.group1 ?? {})
            .filter((document) => document.selected)
            .length

          // At least one group1 document must be selected
          if (countSelectedGroup1Documents < 1) {
            context.addIssue({
              code: 'custom',
              message: 'At least one document must be selected',
              path: ['root'],
            })
          }
        })
    ),
    mode: 'onBlur',
    defaultValues: meetingData,
  })
  const hasErrors = Object.keys(form.formState.errors).length > 0

  useEffect(() => {
    form.trigger()
  }, [form, meetingData])

  useSetAppBoxContent(
    form.formState.errors.root
      ? {
        Element: (
          <Alert severity="warning">
            {form.formState.errors.root.message}
          </Alert>
        ),
        state: 'group-1-errors',
      }
      : {
        Element: (
          <Alert severity="success">
            You have selected enough documents from Group 1 to continue.
          </Alert >
        ),
        state: 'group-1-no-errors',
      }
  )

  return <>
    <Helmet>
      <title>Application Meeting</title>
    </Helmet>
    <Container component="main" maxWidth="xs">
      <Typography component="h1" variant="h5" marginTop={8} marginBottom={3}>
        Which of the following group 1 documents do you own?
      </Typography>
      {Object.entries(identityDocuments.group1 ?? {}).map(([key, value], index) => {
        const groupKey = key as keyof typeof identityDocuments.group1
        const mandatory = groupKey === 'passport'
          ? _hasPassport
            ? 'present'
            : 'not-present'
          : groupKey === 'driverLicence'
            ? _hasDriverLicenceWithPhoto
              ? 'present'
              : 'not-present'
            : undefined
        return (
          <IdentityDocumentCard
            key={key}
            document={value}
            mandatory={mandatory}
            {...(filterIdentityDocumentCardProps({
              selected: (meetingData.group1 ?? {})[groupKey]?.selected,
              onSelect: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group1', groupKey as never, 'selected', setMeetingData);
                form.setValue('group1', newMeetingData.group1)
              },
              eBulkApplication: getEBulkApplicationByID.data,
              verified: (meetingData.group1 ?? {})[groupKey]?.verified,
              onVerify: () => {
                const newMeetingData = toggleMeetingData(meetingData, 'group1', groupKey as never, 'verified', setMeetingData)
                form.setValue('group1', newMeetingData.group1)
              },
            }))}
            sx={{ marginBottom: 2 }}
          />
        )
      })}
      {form.formState.errors.root && (
        <Alert severity="error">
          <AlertTitle>Errors</AlertTitle>
          {form.formState.errors.root.message}
        </Alert>
      )}
      <TestingInfoBlock>
        <Alert severity="info" sx={{ mt: 3 }}>
          <AlertTitle>Only shown in Test Mode</AlertTitle>
          You have selected the following documents so far:
          <ol>
            {getIdentityDocumentsForMeetingData(meetingData).map((each) => {
              const qualifier = each.groupKey.endsWith('3mo')
                ? <> <em>(within the last 3 months)</em></>
                : each.groupKey.endsWith('12mo')
                  ? <> <em>(within the last year)</em></>
                  : each.groupKey.endsWith('anyTime')
                    ? <> <em>(must be valid)</em></>
                    : ''
              return <li key={`${each.groupKey}.${each.docKey}`}>{each.doc.title}{qualifier}</li>
            })}
          </ol>
        </Alert>
      </TestingInfoBlock>
      <TestApplicationAlert dbsApp={getEBulkApplicationByID.data} sx={{ marginTop: 3 }} />
      <Button variant="contained" disabled={hasErrors} onClick={() => { navigateWithMeetingData(linkTo('group2')) }} sx={{ mt: 3, mb: 6 }}>
        Select Group 2 Documents
      </Button>
    </Container>
  </>
}

export default Element
