import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import { schemas } from '../generated/api/client';
import { z } from 'zod';

type EBulkApplication = z.infer<typeof schemas.EBulkApplication>

type TestApplicationAlertProps = {
  dbsApp?: EBulkApplication
} & React.ComponentProps<typeof Alert>

const TestApplicationAlert: React.FC<TestApplicationAlertProps> = ({ dbsApp, ...alertProps }) =>
  dbsApp?.test_application === true
    ? (
      <Alert severity="error" {...alertProps}>
        <AlertTitle>Test Application</AlertTitle>
        <Typography>
          This is a <strong>test application</strong> and will <strong>NOT</strong> be submitted to the DBS.
        </Typography>
      </Alert>
    )
    : null;

export default TestApplicationAlert;